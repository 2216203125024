<template>
    <div style="overflow-x: hidden; overflow-y: auto; "
         :style="isMobile() ? {height: '80vh'}:{padding: '0 32px', height: '100vh'}">
        <navbar :header="'Leaderboard'"></navbar>
        <el-row :style="!isMobile() ? {display: 'flex', 'align-items': 'center'}:{}" style="padding: 16px;">
            <el-col :xl="15" :lg="15" :md="13" :sm="24" :xs="24">
                <div class="leaderboard-info">
                    Every month we give away €100 in cash prizes to the top three students on the leaderboard. Earn
                    points by referring your friends through your referral link. Each person that signs up with your
                    link rewards you with 5 points. Once the clock hits zero, prizes are given, points are reset and we
                    start all over again
                </div>
            </el-col>
            <el-col :xl="{span: 8, offset: 1}" :lg="{span: 8, offset: 1}" :md="{span: 10, offset: 1}" :sm="24" :xs="24">
                <flip-countdown style="width: 100%;" deadline="2021-5-31 23:59:59"
                                :style="isMobile() ? {'margin-top': '3vh'}:{}"></flip-countdown>
                <div class="time-left">
                    Time left until next prize giveaway
                </div>
            </el-col>
        </el-row>
        <el-row style="padding: 16px;">
            <el-col style="background: white;" :style="isMobile() ? {padding: '8px'}:{padding: '2vh 4vw'}">
                <data-tables
                        class="leader-board-table  thead-dark"
                        :data="getLeaderboardData" :filters="filters"
                        style="width: 100%;"
                        :page-size="10"
                        :pagination-props="{ pageSizes: [10], layout: 'prev, pager, next, ->, total' }">
                    <el-row slot="tool" style="margin: 10px 0;"
                            :style="!isMobile() ? {display: 'flex', 'align-items': 'center'}:{}">
                        <el-col v-if="getUserLoggedIn && isMobile()" :xl="{span: 4, offset: 10}"
                                :lg="{span: 4, offset: 10}"
                                :md="{span: 4, offset: 10}" :sm="{span: 12}" :xs="{span: 12}"
                                style="margin-bottom: 1vh;">
                            <button class="referral-button"
                                    @click="doCopy(referralLink, 'Link')">
                                Copy my Referral Link
                            </button>
                        </el-col>
                        <el-col v-if="getUserLoggedIn && isMobile()" :xl="{span: 4}" :lg="{span: 4}" :md="{span: 4}"
                                :sm="{span: 12}" :xs="{span: 12}" style="margin-bottom: 1vh;">
                            <button class="referral-button"
                                    @click="doCopy(referralCode, 'Code')">
                                Copy my Referral Code
                            </button>
                        </el-col>
                        <el-col v-if="!getUserLoggedIn && isMobile()" :xl="{span: 4, offset: 10}"
                                :lg="{span: 4, offset: 10}" :md="{span: 4, offset: 10}" :sm="{span: 24}"
                                :xs="{span: 24}" style="margin-bottom: 1vh;">
                            <button class="referral-button"
                                    @click="$router.push({path: '/login/', query: { redirect: '/leaderboard/' }})">
                                Show my Referral Code
                            </button>
                        </el-col>
                        <el-col :xl="12" :lg="12" :md="12" :sm="24" :xs="24">
                            <el-input placeholder="Search Name/Email Id" v-model="filters[0].value"></el-input>
                        </el-col>
                        <el-col v-if="getUserLoggedIn && !isMobile()" :xl="{span: 5, offset: 2}"
                                :lg="{span: 5, offset: 2}"
                                :md="{span: 6}" :sm="{span: 12}" :xs="{span: 12}">
                            <button class="referral-button"
                                    @click="doCopy(referralLink, 'Link')">
                                Copy my Referral Link
                            </button>
                        </el-col>
                        <el-col v-if="getUserLoggedIn && !isMobile()" :xl="{span: 5}" :lg="{span: 5}" :md="{span: 6}"
                                :sm="{span: 12}" :xs="{span: 12}">
                            <button class="referral-button"
                                    @click="doCopy(referralCode, 'Code')">
                                Copy my Referral Code
                            </button>
                        </el-col>
                        <el-col v-if="!getUserLoggedIn && !isMobile()" :xl="{span: 5, offset: 7}"
                                :lg="{span: 5, offset: 7}"
                                :md="{span: 6}" :sm="{span: 12}" :xs="{span: 12}">
                            <button class="referral-button"
                                    @click="$router.push({path: '/login/', query: { redirect: '/leaderboard/' }})">
                                Show my Referral Code
                            </button>
                        </el-col>
                    </el-row>
                    <el-table-column fixed label="#" :prop="'rank'" width="100" class-name="leaderboard-row"
                                     :sortable="true">
                    </el-table-column>
                    <el-table-column width="100">
                        <template slot-scope="scope">
                            <img v-if="[1, 2, 3].includes(scope.row.rank)" :src="getTrophies(scope.row)" alt="trophy">
                        </template>
                    </el-table-column>
                    <el-table-column label="Name" :prop="'name'" :width="isMobile() ? 150:'auto'"
                                     :sortable="true" align="left">
                    </el-table-column>
                    <el-table-column label="Email Id" :prop="'email'" :width="isMobile() ? 200:'auto'"
                                     :formatter="formatEmail" :sortable="true"></el-table-column>
                    <el-table-column label="Points" :prop="'points'" :width="isMobile() ? 200:200"
                                     :sortable="true"></el-table-column>
                </data-tables>
<!--                <el-pagination-->
<!--                        style="margin-bottom: 5vh;"-->
<!--                        hide-on-single-page-->
<!--                        background-->
<!--                        layout="prev, pager, next, ->, total"-->
<!--                        :page-size="10"-->
<!--                        @current-change="handleCurrentChange"-->
<!--                        @next-click.stop="navigateCourse(nextLeaderboardPage)"-->
<!--                        @prev-click.stop="navigateCourse(previousLeaderboardPage)"-->
<!--                        :total="totalSearchCount">-->
<!--                </el-pagination>-->
            </el-col>
        </el-row>
    </div>
</template>

<script>
  import Navbar from '../../main_components/Navbar';
  import FlipCountdown from 'vue2-flip-countdown';

  export default {
    name: 'Leaderboard',
    components: {Navbar, FlipCountdown},
    mounted() {
      let self = this;
      self.$store.state.loadingScreen = true;
      self.getReferralLink().then(response => {
        self.getLeaderBoardList(1).then(r => {
          self.$store.state.loadingScreen = false;
        }).catch(err => {
          self.$store.state.loadingScreen = false;
        });
      }).catch(error => {
        self.$store.state.loadingScreen = false;
      });
      this.$store.state.totalLeaderboardCount = this.leaderBoardData.length;
    },
    data: function() {
      return {
        nextLeaderboardPage: '',
        previousLeaderboardPage: '',
        totalSearchCount: 0,
        showReferralLink: false,
        referralLink: '',
        referralCode: '',
        leaderBoardData: [],
        filters: [
          {
            value: '',
            prop: ['name', 'email'],
          }],
      };
    },
    computed: {
      getLeaderboardData: function() {
        return this.leaderBoardData;
      },
    },
    methods: {
      navigateCourse: function(page) {
        let self = this;
        self.$store.state.loadingScreen = true;
        self.getLeaderBoardList(page).then(response => {
          self.$store.state.loadingScreen = false;
        }).catch(err => {
          self.$store.state.loadingScreen = false;
        });
      },
      handleCurrentChange(val) {
        let self = this;
        self.$store.state.currentPageNumber = val;
        self.$store.state.loadingScreen = true;
        self.getLeaderBoardList(val).then(response => {
          self.$store.state.loadingScreen = false;
        }).catch(err => {
          self.$store.state.loadingScreen = false;
        });
      },
      doCopy: function(toCopy, title) {
        let self = this;
        self.$copyText(toCopy).then(function(e) {
          self.$message({
            title: 'Successful',
            message: title + ' copied, now share it with your mass following ;)',
            type: 'success',
            duration: 3000,
            showClose: true,
          });
        }, function(e) {
          alert('Can not copy');
        });
      },
      getReferralLink: function() {
        let self = this;
        let url = self.getBaseUrl() + '/accounts/referral/get/';
        return new Promise((resolve, reject) => {
          self.$store.state.loadingScreen = true;
          $.ajax({
            beforeSend: function(request) {
              if (self.getAuthToken() !== '')
                request.setRequestHeader('Authorization', 'Token ' + self.getAuthToken());
            },
            url: url,
            type: 'GET',

            success: function(response) {
              self.referralLink = response.link;
              self.referralCode = response.code;
              // self.$store.state.loadingScreen = false;
              resolve();
            },
            error: function() {
              self.$message({
                title: 'Error',
                message: 'Error generating referral link. Please contact the administrator.',
                type: 'success',
                duration: 7000,
                showClose: true,
              });
              // self.$store.state.loadingScreen = false;
              reject();
            },
          });
        });

      },
      formatEmail: function(row, column, cellValue) {
        let cellValueList = cellValue.split('@');
        let firstHalf = cellValueList[0].charAt(0) + '*'.repeat(cellValueList[0].length - 3) +
            cellValueList[0].charAt(cellValueList[0].length - 1);
        let secondHalf = cellValueList[1].charAt(0) + '*'.repeat(cellValueList[1].length - 3) +
            cellValueList[1].charAt(cellValueList[1].length - 1);
        return firstHalf + '@' + secondHalf;
      },
      getLeaderBoardList: function(page = 1) {

        return new Promise((resolve, reject) => {
          let self = this;
          let url = '';
          if (typeof page !== 'number') {
            url = page;
          } else {
            url = `${self.getBaseUrl()}/accounts/leaderboard/list?page=${page}`;
          }
          url = `${self.getBaseUrl()}/accounts/leaderboard/list`;
          $.ajax({
            url: url,
            type: 'GET',
            success: function(response) {
              self.leaderBoardData = response;
              // self.nextLeaderboardPage = response.next;
              // self.previousLeaderboardPage = response.previous;
              self.totalSearchCount = response.length;
              // self.$store.state.loadingScreen = false;
              resolve();
            },
            error: function() {
              // self.$store.state.loadingScreen = false;
              reject();
            },
          });
        });
      },
      getTrophies: function(row) {
        if ([1, 2, 3].includes(row.rank)) {
          return `${this.publicPath}img/rank-trophy-${row.rank}.svg`;
        }
      },
    },
  };
</script>

<style>
    .leaderboard-row {
        text-align: center;
    }

    .flip-card__top, .flip-card__bottom, .flip-card__back-bottom, .flip-card__back::before, .flip-card__back::after {
        background: #9698D6 !important;
    }

    .flip-card__top, .flip-card__bottom, .flip-card__back-bottom, .flip-card__back::before, .flip-card__back::after {
        color: #ffffff !important;
    }

    .flip-card__bottom, .flip-card__back-bottom, .flip-card__bottom-4digits, .flip-card__back-bottom-4digits {
        border-top: none !important;
    }
</style>
<style scoped>
    .time-left {
        font-weight: bold;
        color: #000000;
    }

    .box .el-row {
        margin: auto;
    }

    .leader-board-table tr th {
        font-weight: 600;
    }

    .leader-board-table tbody {
        border: 1px solid #EDEEF5;
    }

    .leader-board-table thead {
        border: 1px solid #EDEEF5 !important;
    }

</style>
